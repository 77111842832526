import React, { useState } from 'react'
import styled from 'styled-components'
import { useQuery } from '@apollo/client'
import { useHistory } from 'react-router'
import Select from 'react-select'
import CloseIcon from '@material-ui/icons/Close'

import HomeBigItem from '../../layout/HomeBigItem'
import { backgroundGray } from '../../layout/colors'
import canteens from '../../queries/canteens'
import LoaderSpinner from '../../layout/loader'
import { customStyles } from '../editUser/Main'

interface Props {
  searchValue: string
}

const Canteens = ({ searchValue }: Props) => {
  const history = useHistory()
  const [filter, setFilter] = useState('')
  let selectRef: any = null

  const { loading, error, data } = useQuery(canteens)

  if (loading) return <LoaderSpinner />

  if (error) return <p>Error :(</p>

  const filtered = data.canteens.filter((item: any) =>
    searchValue.length > 0
      ? (item.name.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
          item.region.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0) &&
        item.region.toLowerCase().indexOf(filter.toLowerCase()) >= 0
      : item.region.toLowerCase().indexOf(filter.toLowerCase()) >= 0
  )

  let regions: string[] = []

  for (const canteen of data.canteens) {
    regions.push(canteen.region)
  }

  regions = regions.filter((item, pos) => regions.indexOf(item) == pos)

  const options = []
  for (const region of regions) {
    options.push({ value: region, label: `${region} kraj` })
  }

  return (
    <>
      <Container>
        <FilterContainer>
          <FilterText>Filtr:</FilterText>
          <FilterSelect
            ref={(ref) => {
              selectRef = ref
            }}
            isSearchable={true}
            styles={customStyles}
            options={options}
            value={options.find((obj) => obj.value === filter)}
            // @ts-ignore
            onChange={(e) => setFilter(e ? e.value : '')}
            placeholder={'Filtrovat kraj'}
            noOptionsMessage={() => 'Žádné kraje neodpovídají zadanému filtru'}
          />
          <UnsetFilter onClick={() => selectRef.clearValue()} />
        </FilterContainer>

        <ItemsContainer>
          {filtered.map((item: any) => {
            return (
              <>
                <HomeBigItem
                  item={item}
                  key={item}
                  onClick={() => history.push(`/canteen/1`)}
                  /* onClick={() => history.push(`/canteen/${item.id}`)} */
                />
              </>
            )
          })}
        </ItemsContainer>
      </Container>
    </>
  )
}

export default Canteens

const Container = styled.div`
  padding: 10px 10%;
  background-color: ${backgroundGray};
`

const ItemsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`

const FilterText = styled.p`
  margin-right: 20px;
`

const FilterSelect = styled(Select)`
  width: 250px;
`

const UnsetFilter = styled(CloseIcon)`
  position: relative;
  bottom: 0px;
  left: -75px;
  color: hsl(0, 0%, 80%);
  transition: 2s;

  &:hover {
    color: #777777;
  }
`
