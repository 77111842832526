import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { useMutation } from '@apollo/client'
import { useQuery } from '@apollo/client'
import { useParams, useHistory } from 'react-router'

import { backgroundGray, navy } from '../../layout/colors'
import TextInput from '../../layout/textInput'
import LoaderSpinner from '../../layout/loader'
import { InputValueStateType } from '../../constants/types'
import foodAdmin from '../../queries/foodAdmin'
import editFood from '../../mutations/editFood'
import oneFood from '../../queries/oneFood'

interface Props {
  userId: number
}

const Main = ({ userId }: Props) => {
  const history = useHistory()
  const { id }: { id: string } = useParams()

  if (id) {
    userId = parseInt(id)
  }

  const [submitButtonInactive, setSubmitButtonInactive] = useState(true)
  const [error, setError] = useState(false)

  const [formState, setFormState] = useState<InputValueStateType>({
    name: { value: '', isInvalid: false },
  })
  const refetchQueries = { refetchQueries: [{ query: foodAdmin }] }
  const [reg] = useMutation(editFood, {
    ...refetchQueries,
  })

  function handleSubmit() {
    if (formState.name.isInvalid) {
      return setError(true)
    }
    const data = {
      name: formState.name.value,
      id: parseInt(id),
    }
    setError(false)
    history.replace('/food')
    return reg({ variables: { data } })
  }

  const getActualFood = useQuery(oneFood, {
    variables: { id: parseInt(id) },
  })

  useEffect(() => {
    setFormState({
      name: {
        value: getActualFood.loading ? '' : getActualFood.data.food.food.name,
        isInvalid: false,
      },
    })
    setSubmitButtonInactive(true)
  }, [getActualFood.data])

  useEffect(() => {
    if (formState.name.isInvalid) setSubmitButtonInactive(true)
    else setSubmitButtonInactive(false)
  }, [formState])

  function handleValidation(value: string) {
    return false
  }

  if (getActualFood.loading) {
    return <LoaderSpinner />
  }

  return (
    <>
      <Container100vh>
        <Container>
          <LoginForm>
            <Heading>Úprava Jídla {getActualFood.data.food.food.name}</Heading>
            <TextInput
              type="text"
              placeholder="Název jídla"
              name="text"
              value={formState.name.value}
              isInvalid={formState.name.isInvalid}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  name: {
                    value: e.target.value,
                    isInvalid: handleValidation(e.target.value),
                  },
                })
              }
            />
            <div>{error && <LoginError>Hesla se neshodují</LoginError>}</div>
            <Submit inactive={submitButtonInactive} onClick={handleSubmit}>
              Upravit
            </Submit>
          </LoginForm>
        </Container>
      </Container100vh>
    </>
  )
}

export default Main

const Container100vh = styled.div`
  min-height: calc(100vh - 208.88px);

  display: flex;
  justify-content: center;
  align-items: center;

  background-image: url('/img/background_texture.jpg');
  background-attachment: fixed;
  background-position: center;
  background-size: cover;

  @media (min-width: 366px) {
    min-height: calc(100vh - 219.88px);
  }
`

const Container = styled.div`
  flex: 1;
  padding: 30px 0;
  height: 100%;
`

const LoginForm = styled.div`
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 20px 20px 50px 20px;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  border-radius: 5px;
  background-color: white;

  @media (min-width: 500px) {
    padding: 20px 50px 50px 50px;
    width: 70%;
  }

  @media (min-width: 600px) {
    width: 50%;
  }

  @media (min-width: 900px) {
    width: 30%;
  }

  @media (min-width: 2000px) {
    width: 20%;
  }

  @media (min-width: 3000px) {
    width: 15%;
  }
`

interface SubmitProps {
  inactive: boolean
}

const Submit = styled.button<SubmitProps>`
  margin: 20px 0;
  border: none;
  padding: 15px;
  background-color: ${navy};
  color: white;
  border-radius: 5px;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  outline-color: #005471;
  cursor: pointer;
  ${(props) =>
    props.inactive &&
    css`
      pointer-events: none;
      cursor: default;
      color: ${backgroundGray};
      background-color: #00394d;
    `}
`

const Heading = styled.h2`
  text-align: center;
`

const LoginError = styled.span`
  color: #fc6969;
  font-weight: 500;
  font-size: 0.81em;
  position: absolute;
  margin-bottom: 0.75em;
  margin-top: -0.8em;
  display: flex;
  justify-content: flex-start;
`
