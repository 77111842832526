import React, { useState } from 'react'
import styled from 'styled-components'
import Search from '../../layout/Search'
import Canteens from './Canteens'

const Main = () => {
  const [searchValue, setSearchValue] = useState('')

  return (
    <>
      <HeaderImage searchValue={searchValue}>
        <Center>
          <SearchHeading>Vyhledat jídelnu</SearchHeading>
          <Search
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            clearSearch={() => setSearchValue('')}
            placeholder="např.: CZ Holding"
          />
        </Center>
      </HeaderImage>
      <Canteens searchValue={searchValue} />
    </>
  )
}

export default Main

const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
`

const SearchHeading = styled.h1`
  margin-bottom: 0;
`

interface HeaderImageProps {
  searchValue: string
}

const HeaderImage = styled.div<HeaderImageProps>`
  background-image: url('/img/background_texture.jpg');
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  overflow: visible;
  width: 100%;
  min-height: 200px;
  height: ${(props) => (props.searchValue ? '10vh' : '50vh')};
  transition: height 0.5s;
`
