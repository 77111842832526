import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { useQuery } from '@apollo/client'
import styled from 'styled-components'
import { DatePicker, Space } from 'antd'
import dayjs from 'dayjs'
import 'dayjs/locale/cs'
import locale from 'antd/locale/cs_CZ'

import ColorButton from '../../layout/ColorButon'
import LoaderSpinner from '../../layout/loader'
import OfferTable, { ExpandButton } from '../canteen/OfferTable'
import { Link } from 'react-router-dom'
import canteenOfferHistory from '../../queries/canteenOfferHistory'

const History = () => {
  const { id }: { id: string } = useParams()
  const buttonRef = React.useRef<HTMLButtonElement>(null)

  const dateConstant = 604_800_000 // 7 days in ms

  const now = useMemo(() => new Date().getTime(), [])

  const [range, setRange] = useState({ from: now - dateConstant, to: now })

  const { loading, data } = useQuery(canteenOfferHistory, {
    variables: { id: parseInt(id), from: range.from.toString(), to: range.to.toString() },
    context: { clientName: 'api2' },
  })

  useEffect(() => {
    !loading &&
      data.canteenOffers.length > 7 &&
      buttonRef.current?.scrollIntoView({ behavior: 'auto' })
  }, [loading])

  if (loading) {
    return <LoaderSpinner />
  }

  console.log(data.canteenOffers)

  return (
    <>
      <Container100vh>
        <Container>
          <Inner>
            {/* <h1>Jídelna {data.canteen.currentCanteen.name}</h1>
            <p>{data.canteen.currentCanteen.region} kraj</p> */}
            <PickerContainer>
              <Label>Vyberte datum</Label>
              <CustomRangePicker
                defaultValue={[dayjs(range.from), dayjs(range.to)]}
                // @ts-ignore
                locale={locale}
                onCalendarChange={(dates) => {
                  console.log(dates)
                  if (dates && dates[0] && dates[1])
                    setRange({ from: dates[0].toDate().getTime(), to: dates[1].toDate().getTime() })
                }}
              />
            </PickerContainer>
            {!data.canteenOffers.length ? (
              <EmptyMessage>Bohužel jsme nic neneašli :(</EmptyMessage>
            ) : (
              <OfferTable offer={data.canteenOffers} hideExpandButton disableFilter />
            )}
          </Inner>
        </Container>
      </Container100vh>
    </>
  )
}

export default History

const EmptyMessage = styled.div``

const PickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`

const Label = styled.label`
  margin-top: 1rem;
  font-size: 1.125rem;
  font-weight: 700;
`

const CustomRangePicker = styled(DatePicker.RangePicker)`
  width: fit-content;
  padding: 0.625rem 1rem;
  margin-bottom: 2rem;
  border-radius: 5px;
  border: none;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
`

const Container100vh = styled.div`
  min-height: calc(100vh - 208.88px);

  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;

  background-image: url('/img/background_texture.jpg');
  background-attachment: fixed;
  background-position: center;
  background-size: cover;

  @media (min-width: 366px) {
    min-height: calc(100vh - 219.88px);
  }
`

const Container = styled.div`
  flex: 1;
  height: 100%;
`

const Inner = styled.div`
  text-align: center;
  background-color: white;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  width: 80%;
  margin: 10px auto;
  border-radius: 5px;
  padding: 20px 20px;
`

const AddPhoto = styled(Link)`
  display: inline-flex;
  align-items: center;
  color: white;
  text-decoration: none;
  margin: 0 auto;
`

const OfferColorButton = styled(ColorButton)`
  margin-top: 20px;
`

const StravaLink = styled.a`
  background-color: #005471;
  color: white;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  border: 2px solid #005471;
  border-radius: 5px;
  box-sizing: border-box;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  text-decoration: none;
  display: inline-block;
  margin: 10px 0;
  padding: 10px 15px;

  &:hover {
    box-shadow: 0rem 0.5rem 1.5rem rgb(0 0 0 / 30%);
    //transform: scale(1.01);
  }
`
