import { gql } from '@apollo/client'

export default gql`
  query Query($tags: [String!]!) {
    generateNewFood(tags: $tags) {
      ingredients
      name
      recipe
    }
  }
`
