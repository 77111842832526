import React, { useEffect } from 'react'
import { useParams, Redirect } from 'react-router'
import { useMutation, useQuery } from '@apollo/client'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import DeleteIcon from '@material-ui/icons/Delete'
import styled from 'styled-components'
import { SRLWrapper } from 'simple-react-lightbox'

import LoaderSpinner from '../../layout/loader'
import oneFood from '../../queries/oneFood'
import ColorButton from '../../layout/ColorButon'
import useLogin from '../../hooks/useLogin'
import Comments from './Comments'
import { Link, useLocation } from 'react-router-dom'
import removePhoto from '../../mutations/removePhoto'

interface ImageType {
  s3url: string
  id: number
}

const Food = () => {
  const { user } = useLogin()
  const { id }: { id: string } = useParams()
  const { pathname } = useLocation()

  const { loading, error, data, refetch } = useQuery(oneFood, {
    variables: { id: parseInt(id) },
  })

  const refetchQueries = {
    refetchQueries: [
      {
        query: oneFood,
        variables: {
          id: parseInt(id),
        },
      },
    ],
  }
  const [req] = useMutation(removePhoto, {
    ...refetchQueries,
  })

  useEffect(() => {
    const timeout = setTimeout(() => {
      refetch({ id: parseInt(id) })
    }, 1000)

    return () => clearTimeout(timeout)
  }, [])

  if (error) {
    return <Redirect to={`/`} />
  }

  if (loading) {
    return <LoaderSpinner />
  }

  return (
    <>
      <Container100vh>
        <Container>
          <Inner>
            <h1>{data.food.food.name}</h1>
            <SRLWrapper
              options={{ buttons: { showDownloadButton: false, showAutoplayButton: false } }}
            >
              <ImagesContainer>
                {data.food.img.map((img: ImageType) => {
                  return (
                    <ImageOuter key={img.id}>
                      <ImageContainer>
                        <a href={img.s3url}>
                          <Image src={img.s3url} />
                        </a>
                      </ImageContainer>
                      {user && user.adminLevel == '4' && (
                        <ColorButton
                          child={
                            <RemovePhoto onClick={() => req({ variables: { id: img.id } })}>
                              <DeleteIcon />
                              Smazat fotku
                            </RemovePhoto>
                          }
                        />
                      )}
                    </ImageOuter>
                  )
                  //return <Image url={img.s3url} />
                })}
              </ImagesContainer>
            </SRLWrapper>
            {user && user.adminLevel != '0' ? (
              <ColorButton
                child={
                  <AddPhoto to={`/food/${data.food.food.id}/addPhoto`}>
                    <AddCircleIcon />
                    Přidat fotku
                  </AddPhoto>
                }
              />
            ) : (
              <p>
                Pro přidání komentářů musíte být <NavLink to="/login">přihlášen</NavLink>
              </p>
            )}
            <Comments foodId={parseInt(id)} />
            {/* <p>{data.canteen.currentCanteen.region} region</p> */}
          </Inner>
        </Container>
      </Container100vh>
    </>
  )
}

export default Food

const Container100vh = styled.div`
  min-height: calc(100vh - 208.88px);

  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;

  background-image: url('/img/background_texture.jpg');
  background-attachment: fixed;
  background-position: center;
  background-size: cover;

  @media (min-width: 366px) {
    min-height: calc(100vh - 219.88px);
  }
`

const Container = styled.div`
  flex: 1;
  height: 100%;
`

const Inner = styled.div`
  text-align: center;
  background-color: white;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  width: 80%;
  margin: 10px auto;
  border-radius: 5px;
  padding: 20px 20px;
`

const ImagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0;
  flex-direction: column;

  @media (min-width: 530px) {
    flex-direction: row;
  }
`

interface ImageProps {
  src: string
}

const ImageContainer = styled.div`
  background-position: center;
  background-size: cover;
  height: 300px;
  width: 100%;
  overflow: hidden;
  object-fit: cover;
  max-width: 420px;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  border-radius: 5px;
  margin: 10px 0;
`

const Image = styled.img`
  height: 300px;
  width: 100%;
  overflow: hidden;
  object-fit: cover;
  max-width: 420px;
`

const AddPhoto = styled(Link)`
  display: inline-flex;
  align-items: center;
  color: white;
  text-decoration: none;
  margin: 0 auto;
`

const RemovePhoto = styled.div`
  display: inline-flex;
  align-items: center;
  color: white;
  text-decoration: none;
  cursor: pointer;
  margin: 0 auto;
`

const ImageOuter = styled.div`
  max-width: 420px;
  @media (min-width: 530px) {
    flex: 1 0 420px !important;
    margin: 10px auto;
    flex-basis: min-content;
  }
`

const NavLink = styled(Link)`
  color: black;
`
