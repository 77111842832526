import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom'
import DeleteIcon from '@material-ui/icons/Delete'
import { useMutation } from '@apollo/client'

import { backgroundGray, navy } from '../../layout/colors'
import removeCanteenOffer from '../../mutations/removeCanteenOffer'
import canteen from '../../queries/canteen'
import useLogin from '../../hooks/useLogin'

export interface FoodType {
  name?: string
  id: number
}

interface Props {
  food: any
  offerId: number
  canteenUserId?: number
  color?: string
  isLastDay?: boolean
  isLast?: boolean
}

const Food = ({ food, offerId, canteenUserId, color, isLastDay, isLast }: Props) => {
  const history = useHistory()
  const { id }: { id: string } = useParams()
  const { user } = useLogin()
  const [deleted, setDeleted] = useState<any>([])

  const refetchQueries = {
    refetchQueries: [{ query: canteen, variables: { id: parseInt(id) } }],
  }

  const [req] = useMutation(removeCanteenOffer, {
    ...refetchQueries,
  })

  function handleDelete(foodId: number) {
    req({ variables: { data: { foodId, offerId, canteenId: parseInt(id) } } })
    setDeleted([...deleted, foodId])
  }

  return (
    <>
      {food.length !== 0 ? (
        <>
          {food.map((pokrm: FoodType, i: number) => {
            if (deleted.includes(pokrm.id)) return <></>
            return (
              <Row>
                <Td onClick={() => history.push(`/food/${pokrm.id}`)}>Oběd {i + 1}</Td>
                <Td onClick={() => history.push(`/food/${pokrm.id}`)}>{pokrm.name}</Td>
                <Td>
                  {user && (user.id === canteenUserId || user.adminLevel == '4') && (
                    <Delete
                      onClick={() => {
                        handleDelete(pokrm.id)
                      }}
                      bgColor={color}
                    />
                  )}
                </Td>
              </Row>
            )
          })}
          {isLastDay && !isLast ? (
            <LineRow>
              <LineBox colSpan={3}>
                <InnerLine></InnerLine>
              </LineBox>
            </LineRow>
          ) : null}
        </>
      ) : (
        <Row>
          <Td>Nic v nabídce</Td>
          <Td></Td>
          <Td></Td>
        </Row>
      )}
    </>
  )
}

export default Food

const Row = styled.tr`
  &:nth-child(odd) {
    background-color: ${backgroundGray};
  }

  &:hover {
    background-color: #e9e9e9;
  }
`

const Td = styled.td`
  padding: 5px 10px;
  cursor: pointer;

  &:first-child {
    width: 100px;
  }

  &:last-child {
    display: flex;
    justify-content: flex-end;
  }
`

interface DeleteIconProps {
  bgColor?: string
}

const Delete = styled(DeleteIcon)<DeleteIconProps>`
  color: ${(props) => props.bgColor || navy};
`

const LineRow = styled.tr``

const LineBox = styled.td`
  padding: 10px 0;
`

const InnerLine = styled.div`
  background-color: #c9c9c9;
  height: 3px;
`
