import React from 'react'
import { useQuery } from '@apollo/client'
import styled from 'styled-components'

import LoaderSpinner from '../../layout/loader'
import { UserType } from '../../constants/types'
import Canteen from './Canteen'
import { backgroundGray, navy } from '../../layout/colors'
import canteens from '../../queries/canteens'

const Main = () => {
  const { data, loading } = useQuery(canteens)

  if (loading) return <LoaderSpinner />
  return (
    <>
      <Container100vh>
        <Container>
          <Inner>
            <h1>Jídelny</h1>
            <TableContainer>
              <Table>
                <TableHead>
                  <Th>Název</Th>
                  <Th>Kraj</Th>
                  <Th></Th>
                  <Th></Th>
                  <Th></Th>
                </TableHead>
                {data.canteens.map((item: UserType) => {
                  return <Canteen item={item} />
                })}
              </Table>
            </TableContainer>
          </Inner>
        </Container>
      </Container100vh>
    </>
  )
}

export default Main

const Container100vh = styled.div`
  min-height: calc(100vh - 208.88px);

  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;

  background-image: url('/img/background_texture.jpg');
  background-attachment: fixed;
  background-position: center;
  background-size: cover;

  @media (min-width: 366px) {
    min-height: calc(100vh - 219.88px);
  }
`

const Container = styled.div`
  flex: 1;
  height: 100%;
`

const Table = styled.table`
  text-align: left;
  background-color: white;
  border-collapse: collapse;
  width: 100%;
`

const Inner = styled.div`
  text-align: center;
  background-color: white;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  width: 80%;
  margin: 10px auto;
  border-radius: 5px;
  padding: 20px 20px;
`

const TableContainer = styled.div`
  overflow-x: scroll;
`

const TableHead = styled.tr`
  background-color: ${backgroundGray};
`
const Th = styled.th`
  padding: 10px;
  color: white;
  background-color: ${navy};
`
