import { gql } from '@apollo/client'

interface Canteens {
  id: number
  firstName: String
  lastName: String
}

export interface CanteenType {
  city: string
  items: Canteens
}

export default gql`
  query Query($id: Int!, $offset: Int, $limit: Int, $search: String) {
    canteenFood(id: $id, offset: $offset, limit: $limit, search: $search) {
      id
      name
      img
    }
  }
`
