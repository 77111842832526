import { useLazyQuery } from '@apollo/client'
import { useEffect } from 'react'
import generateNewFood from '../../../queries/generateNewFood'
import styled from 'styled-components'
import Loader from 'react-loader-spinner'

interface Props {
  tags: string[]
}

const GenerateAi = (props: Props) => {
  const [getFood, { loading, data, error }] = useLazyQuery(generateNewFood, {
    variables: { tags: props.tags },
    context: { clientName: 'api2' },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    getFood()
  }, [])

  return (
    <>
      <h3>Vygenerovat nový recept</h3>
      {loading && (
        <Loading>
          <Loader type="Oval" color={'#ee982b'} height={50} width={50} />
          Generujeme unikátní recept, může to chvíli trvat
        </Loading>
      )}
      {data?.generateNewFood && (
        <>
          <div>
            <p>Název: {data.generateNewFood.name}</p>
            <div>
              <p>Ingredience:</p>
              <ul>
                {data.generateNewFood.ingredients.map((ingredient: any) => (
                  <li>{ingredient}</li>
                ))}
              </ul>
            </div>
            <p>Postup: {data.generateNewFood.recipe}</p>
          </div>
          <SubmitButton
            onClick={() => {
              console.log('click')
              getFood()
            }}
          >
            Vygenerovat znovu
          </SubmitButton>
        </>
      )}
    </>
  )
}

export default GenerateAi

const SubmitButton = styled.button`
  background-color: #ee982b;
  color: white;
  display: flex;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  border: 2px solid #ee982b;
  border-radius: 5px;
  box-sizing: border-box;
  transition: 0.5s;
  padding: 10px 15px;
  height: fit-content;
  margin: 1rem auto;

  &:hover {
    box-shadow: 0rem 0.5rem 1.5rem rgb(0 0 0 / 30%);
  }
`

const Loading = styled.div`
  text-align: center;
`
