import React, { useState, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import { useMutation, useQuery } from '@apollo/client'
import { Rating } from 'react-simple-star-rating'

import { navy, backgroundGray } from '../../layout/colors'
import { TextInputProps } from '../../layout/textInput'
import { InputValueStateType } from '../../constants/types'
import { Redirect, useHistory } from 'react-router'
import useLogin from '../../hooks/useLogin'
import addComment from '../../mutations/addComment'
import comments from '../../queries/comments'
import LoaderSpinner from '../../layout/loader'
import Comment from './Comment'
import { Link } from 'react-router-dom'

interface Props {
  foodId: number
}

const Comments = ({ foodId }: Props) => {
  const { user } = useLogin()
  const ref = useRef<HTMLButtonElement>(null)
  const [submitButtonInactive, setSubmitButtonInactive] = useState(true)
  const [err, setError] = useState(false)
  const refetchQueries = { refetchQueries: [{ query: comments, variables: { id: foodId } }] }

  const { loading, error, data } = useQuery(comments, {
    variables: { id: foodId },
  })

  const [formState, setFormState] = useState<InputValueStateType>({
    text: { value: '', isInvalid: false },
    rating: { value: '', isInvalid: true },
  })

  const [reg] = useMutation(addComment, {
    onCompleted() {
      setFormState({
        text: { value: '', isInvalid: false },
        rating: { value: '', isInvalid: true },
      })
    },
    ...refetchQueries,
  })

  function handleSubmit() {
    if (formState.text.isInvalid || formState.rating.isInvalid) {
      return setError(true)
    }
    const data = {
      text: formState.text.value,
      rating: parseInt(formState.rating.value),
      foodId,
    }
    setError(false)
    return reg({ variables: { data } })
  }

  useEffect(() => {
    if (formState.text.isInvalid || formState.text.value == '' || formState.rating.isInvalid)
      setSubmitButtonInactive(true)
    else setSubmitButtonInactive(false)
  }, [formState])

  function handleValidation(value: string, type: string) {
    if (type === 'text') {
      return value.trim() == ''
    }
    if (type === 'rating') {
      return !/^[0-9][0][0]?$/.test(value)
    }
    return false
  }

  function handleEnter(event: any) {
    if (event.keyCode === 13 && ref && ref.current) {
      event.preventDefault()
      ref.current.click()
    }
  }

  useEffect(() => {
    window.addEventListener('keyup', handleEnter)

    return () => {
      window.removeEventListener('keyup', handleEnter)
    }
  }, [])

  if (error) {
    return <Redirect to={`/`} />
  }

  if (loading) {
    return <LoaderSpinner />
  }

  return (
    <>
      <Container>
        <h2>Hodnocení</h2>
        {user && user.adminLevel != '0' ? (
          <ComentForm>
            <TextArea
              placeholder="Kritika se bere, ale slušná a konstruktivní - nepiš byl to hnus, piš dneska mi to nechutnalo, ideálně napiš proč - neokořeněné, přesolené...
              &#10;Paní kuchařky fotí všechny fotky, projektu fandí, a chtějí vařit tak, aby to co nejvíc lidem chutnalo - takže když ti to nechutná tak napiš proč ať vědí co udělat příště líp. Díky"
              value={formState.text.value}
              isInvalid={formState.text.isInvalid}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  text: {
                    value: e.target.value,
                    isInvalid: handleValidation(e.target.value, 'text'),
                  },
                })
              }
            />
            <p>
              <Rating
                transition
                size={28}
                onClick={(rate) =>
                  setFormState({
                    ...formState,
                    rating: {
                      value: rate.toString(),
                      isInvalid: handleValidation(rate.toString(), 'rating'),
                    },
                  })
                }
                ratingValue={parseInt(formState.rating.value)}
              />
            </p>

            <div>{err && <LoginError>Text obsahuje nepovolené znaky</LoginError>}</div>
            <Submit ref={ref} inactive={submitButtonInactive} onClick={handleSubmit}>
              Přidat Komentář
            </Submit>
          </ComentForm>
        ) : (
          <p>
            Pro přidání komentářů musíte být <NavLink to="/login">přihlášen</NavLink>
          </p>
        )}
      </Container>
      <CommentsSection>
        {data.getComments.map((comment: any) => (
          <Comment comment={comment} foodId={foodId} />
        ))}
      </CommentsSection>
    </>
  )
}

export default Comments

const Container = styled.div``

const ComentForm = styled.div``

interface SubmitProps {
  inactive: boolean
}

const Submit = styled.button<SubmitProps>`
  margin: 20px 0;
  border: none;
  padding: 15px;
  background-color: ${navy};
  color: white;
  border-radius: 5px;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  outline-color: #005471;
  cursor: pointer;
  ${(props) =>
    props.inactive &&
    css`
      pointer-events: none;
      cursor: default;
      color: ${backgroundGray};
      background-color: #00394d;
    `}
`

const LoginError = styled.span`
  color: #fc6969;
  font-weight: 500;
  font-size: 0.81em;
  position: absolute;
  margin-bottom: 0.75em;
  margin-top: -0.8em;
  display: flex;
  justify-content: flex-start;
`

const TextArea = styled.textarea<TextInputProps>`
  width: 50%;
  resize: vertical;
  font-family: inherit;
  margin: 20px 0;
  border: 2px solid transparent;
  outline: none;
  padding: 15px;
  background-color: ${backgroundGray};
  border-radius: 5px;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  height: 5rem;

  ${({ isInvalid }) =>
    isInvalid &&
    css`
      border: 2px solid red;
    `}
`

const CommentsSection = styled.div`
  max-width: 50vw;
  margin: 0 auto;
`

const NavLink = styled(Link)`
  color: black;
`
