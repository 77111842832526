import styled from 'styled-components'

import Image from './Content'

const FoodWaste = () => {
  return (
    <Container100vh>
      <Content>
        <Image />
      </Content>
    </Container100vh>
  )
}

export default FoodWaste

const Container100vh = styled.div`
  min-height: calc(100vh - 208.88px);

  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;

  background-image: url('/img/background_texture.jpg');
  background-attachment: fixed;
  background-position: center;
  background-size: cover;

  @media (min-width: 366px) {
    min-height: calc(100vh - 219.88px);
  }
`

const Content = styled.div`
  text-align: center;
  background-color: white;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  width: 80%;
  margin: 10px auto;
  border-radius: 5px;
  padding: 20px 5rem;
  max-width: 70rem;

  @media (max-width: 900px) {
    padding: 20px 2rem;
  }
`
