import React from 'react'
import { useQuery } from '@apollo/client'
import styled from 'styled-components'

import LoaderSpinner from '../../layout/loader'
import Food, { ApproveFoodType } from './Food'
import photoAdmin from '../../queries/photoAdmin'
import { Redirect } from 'react-router-dom'

const Main = () => {
  const { loading, data } = useQuery(photoAdmin)

  if (loading) return <LoaderSpinner />
  if (!data && !loading) return <Redirect to={'/'} />
  return (
    <>
      <Container100vh>
        <Container>
          <Inner>
            <h1>Nové fotografie</h1>
            <ContentContainer>
              {data.photoAdmin.map((item: ApproveFoodType) => {
                return <Food item={item} />
              })}
            </ContentContainer>
          </Inner>
        </Container>
      </Container100vh>
    </>
  )
}

export default Main

const Container100vh = styled.div`
  min-height: calc(100vh - 208.88px);

  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;

  background-image: url('/img/background_texture.jpg');
  background-attachment: fixed;
  background-position: center;
  background-size: cover;

  @media (min-width: 366px) {
    min-height: calc(100vh - 219.88px);
  }
`

const Container = styled.div`
  flex: 1;
  height: 100%;
`

const Inner = styled.div`
  text-align: center;
  background-color: white;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  width: 80%;
  margin: 10px auto;
  border-radius: 5px;
  padding: 20px 20px;
`

const ContentContainer = styled.div``
