import React from 'react'
import styled from 'styled-components'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { useMutation } from '@apollo/client'
import { Link } from 'react-router-dom'

import Buttons, { Button, ButtonLink } from '../Buttons'
import useLogin from '../../../hooks/useLogin'
import logout from '../../../mutations/logout'
import handleLogout from '../../handleLogout'
import { ExternalLink } from '../MobileMenu/HamburgerMenu'

const DesktopMenu = () => {
  const { user } = useLogin()
  const [logoutUser] = useMutation(logout)

  return (
    <Nav>
      <NavLink to="/">Seznam jídelen</NavLink>
      <NavLink to="/about">O nás</NavLink>
      <NavLink to="/foodWaste">Příběh jídla</NavLink>
      <NavLink to="/howItWorks">Jak to funguje</NavLink>
      {user && user.adminLevel == '4' && <NavLink to="/users">Uživatelé</NavLink>}
      {user && user.adminLevel == '4' && <NavLink to="/addCanteen">Přidat jídelnu</NavLink>}
      {user && user.adminLevel == '4' && <NavLink to="/canteens">Administrace jídelen</NavLink>}
      {user && parseInt(user.adminLevel) >= 2 && <NavLink to="/food">Administrace pokrmů</NavLink>}
      {user && parseInt(user.adminLevel) >= 2 && (
        <NavLink to="/photos">Schválení fotografií</NavLink>
      )}
      {user && (
        <NavLink to="/editUser">
          <AccountIcon />
          {user.userName}
        </NavLink>
      )}
      {user && (
        <Href onClick={() => handleLogout(logoutUser)}>
          <LogoutIcon />
          Odhlásit se
        </Href>
      )}
      {!user && <Buttons mobile={false} />}
      <MenuMakerLink mobile={false}>
        <NavLink to="/menuMakerForm/1">Menu maker</NavLink>
      </MenuMakerLink>
    </Nav>
  )
}
export default DesktopMenu

const Nav = styled.nav`
  display: flex;
  align-items: center;
`

export const MenuMakerLink = styled(Button)`
  background-color: #ee982b;
  border: 2px solid #ee982b;
`

const NavLink = styled(Link)`
  padding: 10px;
  text-decoration: none;
  color: black;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
`
const AccountIcon = styled(AccountCircleIcon)`
  margin-right: 5px;
`

const LogoutIcon = styled(ExitToAppIcon)`
  margin-right: 5px;
`

const Href = styled.a`
  padding: 10px;
  text-decoration: none;
  color: black;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
`
