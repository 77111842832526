import styled from 'styled-components'

interface Props {
  setStep: React.Dispatch<React.SetStateAction<number>>
}

const SelectAction = (props: Props) => {
  return (
    <>
      <h2>Upravit</h2>

      <ButtonsBox>
        <SubmitButton>Navrhnout podobné jídlo</SubmitButton>
        <SubmitButton>Navrhnout podobné jídlo z jiné jídelny</SubmitButton>
        <SubmitButton onClick={() => props.setStep(1)}>Navrhnout podobné jídlo AI</SubmitButton>
        <SubmitButton>Vyměnit za jiné</SubmitButton>
      </ButtonsBox>
    </>
  )
}

export default SelectAction

const SubmitButton = styled.button`
  background-color: #ee982b;
  color: white;
  display: flex;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  border: 2px solid #ee982b;
  border-radius: 5px;
  box-sizing: border-box;
  transition: 0.5s;
  padding: 10px 15px;
  height: fit-content;
  margin: 1rem auto;
  width: 100%;
  text-align: center !important;

  &:hover {
    box-shadow: 0rem 0.5rem 1.5rem rgb(0 0 0 / 30%);
  }
`

const ButtonsBox = styled.div`
  text-align: center;
  width: fit-content;
  margin: 0 auto;
`
