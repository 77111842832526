import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router'
import { useQuery } from '@apollo/client'
import { useHistory } from 'react-router'

import LoaderSpinner from '../../layout/loader'
import food from '../../queries/food'
import Search from '../../layout/Search'
import { FoodType } from './Food'
import Loader from 'react-loader-spinner'
import { navy } from '../../layout/colors'
import { ExpandButton } from '../menuMaker/OfferTable'

interface FoodImage extends FoodType {
  img: string
}

const OfferSection = () => {
  const history = useHistory()
  const { id }: { id: string } = useParams()
  const [searchValue, setSearchValue] = useState('')
  const [loaded, setLoaded] = useState<number[]>([])
  const abortController = new AbortController()

  const { loading, data, fetchMore, refetch } = useQuery(food, {
    variables: { id: parseInt(id), offset: 0, limit: 12, search: searchValue },
  })

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (searchValue.length >= 3 || searchValue == '') {
        refetch({ id: parseInt(id), offset: 0, limit: 12, search: searchValue })
      }
    }, 500)

    return () => clearTimeout(timeout)
  }, [searchValue])

  if (loading) {
    return <LoaderSpinner />
  }

  return (
    <>
      <SearchContainer>
        <SearchFood
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          clearSearch={() => setSearchValue('')}
          placeholder={`např.: ${data.canteenFood[0] ? data.canteenFood[0].name : 'Guláš'}`}
        />
      </SearchContainer>

      <Container>
        {data &&
          data.canteenFood.map((food: FoodImage, index: number) => {
            return (
              <FoodItemContainer onClick={() => history.push(`/food/${food.id}`)} key={food.id}>
                <ImageContainer>
                  {!loaded.includes(index) && (
                    <LoaderContainer>
                      <StyledLoader type="Oval" color={navy} height={100} width={100} />
                    </LoaderContainer>
                  )}
                  <Image
                    src={food.img ? food.img : '/img/placeholder.png'}
                    loading="lazy"
                    onLoad={() => setLoaded((state) => [...state, index])}
                    key={`${food.id}_${food.img}`}
                  />
                </ImageContainer>
                <p>{food.name}</p>
              </FoodItemContainer>
            )
          })}
      </Container>
      {data.canteenFood.length >= 12 && (
        <LoadMoreButton
          onClick={() =>
            fetchMore({
              variables: {
                offset: data.canteenFood.length,
              },
            })
          }
        >
          Načíst další
        </LoadMoreButton>
      )}
    </>
  )
}

export default OfferSection

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px 0;
  flex-direction: column;

  @media (min-width: 530px) {
    flex-direction: row;
  }
`

const FoodItemContainer = styled.div`
  flex: 1;
  max-width: 420px;
  text-align: center;
  cursor: pointer;
  margin: 20px 10px;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  border-radius: 5px;
  font-weight: bold;
  transition: 0.5s;

  &:hover {
    box-shadow: 0rem 0.5rem 1.5rem rgb(0 0 0 / 30%);
    //transform: scale(1.01);
  }

  @media (min-width: 530px) {
    flex: 1 0 420px;
  }
`

const Image = styled.img`
  object-fit: cover;
  height: 200px;
  flex-basis: min-content;
  max-width: 420px;
  width: 100%;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  border-radius: 5px;

  @media (min-width: 530px) {
    height: 300px;
  }
`

const ImageContainer = styled.div`
  margin: 20px 25px;
  position: relative;
`

const LoaderContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

const StyledLoader = styled(Loader)``

const SearchContainer = styled.div`
  display: flex;
  justify-content: center;
`

const SearchFood = styled(Search)`
  margin: 0;
`

const LoadMoreButton = styled(ExpandButton)``
