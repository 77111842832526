import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { ApolloClient, InMemoryCache, ApolloProvider, ApolloLink, HttpLink } from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'
import SimpleReactLightbox from 'simple-react-lightbox'

import App from './App'
import reportWebVitals from './reportWebVitals'
import { resolvers, typeDefs } from './resolvers'
import { ConfigProvider } from 'antd'

const errorLink = onError(({ graphQLErrors, networkError }): any => {
  const ignoredErrors = ['Bad credentials']
  // @ts-ignore
  if (graphQLErrors && graphQLErrors[0].message) {
    // @ts-ignore
    if (graphQLErrors[0].message === 'You need to log in') {
      window.localStorage.clear()
      return window.location.reload()
    }
    // @ts-ignore
    if (!ignoredErrors.includes(graphQLErrors[0].message)) {
      alert(graphQLErrors[0].message)
    }
  } else {
    alert(`Status code: 500 something went wrong`)
    console.log(graphQLErrors)
  }
})

const link = createUploadLink({
  uri: `${process.env.REACT_APP_API_URL}graphql`,
})

let token: string

const authLink = setContext((_, { headers }) => {
  if (!token) {
    const loginData = localStorage.getItem('loginData')
    token = loginData ? JSON.parse(loginData).token : ''
  }

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const api2link = new HttpLink({
  uri: `${process.env.REACT_APP_API_V2_URL}/graphql`,
})

const client = new ApolloClient({
  link: ApolloLink.split(
    (operation) => operation.getContext().clientName === 'api2',
    // the string "third-party" can be anything you want,
    // we will use it in a bit
    api2link, // <= apollo will send to this if clientName is "third-party"
    // @ts-ignore
    errorLink.concat(authLink.concat(link)) // <= otherwise will send to this
  ),
  typeDefs,
  resolvers,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          canteenFood: {
            // Don't cache separate results based on
            // any of this field's arguments.
            keyArgs: false,

            // Concatenate the incoming list items with
            // the existing list items.
            merge(existing = [], incoming) {
              return [...existing, ...incoming]
            },
          },
        },
      },
    },
  }),
})

const render = (Component: React.FC) =>
  ReactDOM.render(
    <ApolloProvider client={client}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#005471',
          },
        }}
      >
        <SimpleReactLightbox>
          <Router>
            <Component />
          </Router>
        </SimpleReactLightbox>
      </ConfigProvider>
    </ApolloProvider>,
    document.getElementById('root')
  )

render(App)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
