import React from 'react'
import styled from 'styled-components'
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow'
import ColorButton from './ColorButon'

interface Canteen {
  name: String
  region: String
}

interface Props {
  item: Canteen
  onClick: () => void
}

const HomeBigItem = ({ item, onClick }: Props) => {
  return (
    <Container onClick={onClick}>
      <LeftSection>
        <Name>{item.name}</Name>
        <Region>{item.region} kraj</Region>
      </LeftSection>
      <Button child={['Zobrazit Jídelnu', <DoubleArrowIcon />]} />
    </Container>
  )
}

export default HomeBigItem

const Container = styled.div`
  background-color: white;
  padding: 5px 10px;
  margin: 30px 10px;
  text-align: left;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  flex: 1 0;
  min-width: 200px;

  @media (min-width: 600px) {
    margin: 30px;
    max-width: 200px;
  }
`

const Button = styled(ColorButton)`
  color: red !important;
  margin-right: 20px;
`

const LeftSection = styled.div`
  text-align: center;
`

const Name = styled.h3`
  font-size: 23px;
  margin-bottom: 0;
  text-transform: capitalize;
`

const Region = styled.p`
  margin-top: 0;
  color: #888888;
`
