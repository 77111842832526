import React, { useState, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import { useMutation } from '@apollo/client'

import { navy, backgroundGray } from '../../layout/colors'
import TextInput from '../../layout/textInput'
import { InputValueStateType } from '../../constants/types'
import { useHistory, useParams } from 'react-router'
import addFood from '../../mutations/addFood'
import food from '../../queries/food'
import foodAdmin from '../../queries/foodAdmin'

const AddFood = () => {
  const history = useHistory()
  const ref = useRef<HTMLButtonElement>(null)
  const [submitButtonInactive, setSubmitButtonInactive] = useState(true)
  const [err, setError] = useState(false)
  const { id }: { id: string } = useParams()

  const [formState, setFormState] = useState<InputValueStateType>({
    name: { value: '', isInvalid: false },
  })

  const refetchQueries = {
    refetchQueries: [{ query: food, variables: { id: parseInt(id) } }, { query: foodAdmin }],
  }
  const [reg] = useMutation(addFood, {
    ...refetchQueries,
    onCompleted() {
      history.push(`/canteen/${id}`)
    },
  })

  function handleSubmit() {
    if (formState.name.isInvalid) {
      return setError(true)
    }
    const data = {
      name: formState.name.value,
      canteenId: parseInt(id),
    }
    setError(false)
    return reg({ variables: { data } })
  }

  useEffect(() => {
    if (formState.name.isInvalid) setSubmitButtonInactive(true)
    else setSubmitButtonInactive(false)
  }, [formState])

  function handleValidation(value: string) {
    return !/^[\sa-zá-žA-ZÁ-Ž0-9,._-]+$/.test(value)
  }

  function handleEnter(event: any) {
    if (event.keyCode === 13 && ref && ref.current) {
      event.preventDefault()
      ref.current.click()
    }
  }

  useEffect(() => {
    window.addEventListener('keyup', handleEnter)

    return () => {
      window.removeEventListener('keyup', handleEnter)
    }
  }, [])

  return (
    <Container100vh>
      <Container>
        <Form>
          <Heading>Přidání nového jídla</Heading>
          <TextInput
            type="text"
            placeholder="Název jídla"
            value={formState.name.value}
            isInvalid={formState.name.isInvalid}
            onChange={(e) =>
              setFormState({
                ...formState,
                name: {
                  value: e.target.value,
                  isInvalid: handleValidation(e.target.value),
                },
              })
            }
          />
          <div>{err && <LoginError>Hesla se neshodují</LoginError>}</div>
          <Submit ref={ref} inactive={submitButtonInactive} onClick={handleSubmit}>
            Přidat jídlo
          </Submit>
        </Form>
      </Container>
    </Container100vh>
  )
}

export default AddFood

const Container100vh = styled.div`
  min-height: calc(100vh - 208.88px);

  display: flex;
  justify-content: center;
  align-items: center;

  background-image: url('/img/background_texture.jpg');
  background-attachment: fixed;
  background-position: center;
  background-size: cover;

  @media (min-width: 366px) {
    min-height: calc(100vh - 219.88px);
  }
`

const Container = styled.div`
  flex: 1;
  padding: 30px 0;
  height: 100%;
`

const Form = styled.div`
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 20px 20px 50px 20px;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  border-radius: 5px;
  background-color: white;

  @media (min-width: 500px) {
    padding: 20px 50px 50px 50px;
    width: 70%;
  }

  @media (min-width: 600px) {
    width: 50%;
  }

  @media (min-width: 900px) {
    width: 30%;
  }

  @media (min-width: 2000px) {
    width: 20%;
  }

  @media (min-width: 3000px) {
    width: 15%;
  }
`

interface SubmitProps {
  inactive: boolean
}

const Submit = styled.button<SubmitProps>`
  margin: 20px 0;
  border: none;
  padding: 15px;
  background-color: ${navy};
  color: white;
  border-radius: 5px;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  outline-color: #005471;
  cursor: pointer;
  ${(props) =>
    props.inactive &&
    css`
      pointer-events: none;
      cursor: default;
      color: ${backgroundGray};
      background-color: #00394d;
    `}
`

const Heading = styled.h2`
  text-align: center;
`

const LoginError = styled.span`
  color: #fc6969;
  font-weight: 500;
  font-size: 0.81em;
  position: absolute;
  margin-bottom: 0.75em;
  margin-top: -0.8em;
  display: flex;
  justify-content: flex-start;
`
