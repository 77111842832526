import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import Food, { FoodType } from './Food'
import { backgroundGray, navy } from '../../layout/colors'

interface OfferType {
  id: number
  food: FoodType
  date: string
  map: (offer: OfferType) => JSX.Element
}

interface Props {
  offer: OfferType[]
  canteenUserId?: number
  hideExpandButton?: boolean
  disableFilter?: boolean
  color?: string
}

function convertDate(dateString: string) {
  const date = new Date(parseInt(dateString))
  return date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear()
}

const days = ['Neděle', 'Pondělí', 'Úterý', 'Středa', 'Čtvrtek', 'Pátek']

const OfferTable = ({ offer, canteenUserId, hideExpandButton, disableFilter, color }: Props) => {
  const [displayed, setDisplayed] = useState<OfferType[]>(offer)
  const [opened, setOpened] = useState(false)
  const curr = new Date()
  const endDate = new Date(curr.setDate(curr.getDate() - curr.getDay() + 7))

  useEffect(() => {
    !disableFilter && setDisplayed(displayed.filter((offer) => new Date(offer.date) < endDate))
  }, [])

  useEffect(() => {
    if (disableFilter) return

    opened
      ? setDisplayed(offer)
      : setDisplayed(displayed.filter((offer) => new Date(offer.date) < endDate))
  }, [opened])

  return (
    <>
      <Table>
        {displayed.map((offer: OfferType) => (
          <>
            <TableHead>
              <Th color={color}>Datum</Th>
              <Th color={color}>
                {days.at(new Date(offer.date).getDay())} {convertDate(offer.date)}
              </Th>
              <Th color={color}></Th>
              <Th color={color}></Th>
            </TableHead>
            <Food
              food={offer.food}
              offerId={offer.id}
              canteenUserId={canteenUserId}
              color={color}
            />
          </>
        ))}
      </Table>

      {!hideExpandButton && (
        <div>
          <ExpandButton onClick={() => setOpened(!opened)} color={color}>
            Zobrazit {opened ? 'méňe' : 'více'}
          </ExpandButton>
        </div>
      )}
    </>
  )
}

export default OfferTable

const Table = styled.table`
  text-align: left;
  background-color: white;
  border-collapse: collapse;
  width: 100%;
`

const TableHead = styled.tr`
  background-color: ${backgroundGray};
`

interface TableHeadProps {
  color?: string
}

const Th = styled.th<TableHeadProps>`
  padding: 10px;
  color: white;
  background-color: ${(props) => props.color || navy};
`

export const ExpandButton = styled.button<TableHeadProps>`
  background-color: ${(props) => props.color || '#005471'};
  color: white;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  border: 2px solid #005471;
  border-radius: 5px;
  box-sizing: border-box;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  text-decoration: none;
  display: inline-block;
  margin: 10px 0;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 1rem;

  &:hover {
    box-shadow: 0rem 0.5rem 1.5rem rgb(0 0 0 / 30%);
    //transform: scale(1.01);
  }
`
