import React, { useState, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import { useMutation } from '@apollo/client'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router'
import Select from 'react-select'

import { navy, backgroundGray } from '../../layout/colors'
import TextInput from '../../layout/textInput'
import { InputValueStateType } from '../../constants/types'
import food from '../../queries/food'
import LoaderSpinner from '../../layout/loader'
import addCanteenOffer from '../../mutations/addCanteenOffer'
import { customStyles } from '../editUser/Main'
import FormInfoText from '../../layout/FormInfoText'
import canteen from '../../queries/canteen'

const AddOffer = () => {
  const ref = useRef<HTMLButtonElement>(null)
  const [submitButtonInactive, setSubmitButtonInactive] = useState(true)
  const [err, setError] = useState(false)
  const [added, setAdded] = useState(false)
  const { id }: { id: string } = useParams()

  const foodOffer = useQuery(food, {
    variables: { id: parseInt(id) },
  })

  const [formState, setFormState] = useState<InputValueStateType>({
    date: { value: '', isInvalid: false },
    food: { value: '', isInvalid: false },
  })

  const refetchQueries = { refetchQueries: [{ query: canteen, variables: { id: parseInt(id) } }] }
  const [reg] = useMutation(addCanteenOffer, {
    ...refetchQueries,
    onCompleted() {
      setFormState({
        ...formState,
        date: {
          value: '',
          isInvalid: false,
        },
      })
      setAdded(true)
    },
  })

  function handleSubmit() {
    if (formState.date.isInvalid || formState.food.isInvalid) {
      return setError(true)
    }
    const data = {
      id: parseInt(id),
      date: formState.date.value,
      foodId: parseInt(formState.food.value),
    }
    setError(false)
    return reg({ variables: { data } })
  }

  useEffect(() => {
    if (formState.date.isInvalid || formState.food.isInvalid) setSubmitButtonInactive(true)
    else setSubmitButtonInactive(false)
  }, [formState])

  function handleValidation(value: string) {
    return !/^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/.test(value)
  }

  function handleEnter(event: any) {
    if (event.keyCode === 13 && ref && ref.current) {
      event.preventDefault()
      ref.current.click()
    }
  }

  useEffect(() => {
    window.addEventListener('keyup', handleEnter)

    return () => {
      window.removeEventListener('keyup', handleEnter)
    }
  }, [])

  function selectOnChange(e?: any) {
    setFormState({
      ...formState,
      food: {
        value: e ? e.value : '1',
        isInvalid: false,
      },
    })
  }

  if (foodOffer.loading) {
    return <LoaderSpinner />
  }

  const options = []
  for (const food of foodOffer.data.canteenFood) {
    options.push({ value: food.id, label: food.name })
  }

  return (
    <Container100vh>
      <Container>
        <Form>
          <Heading>Přidání nové nabídky</Heading>
          <TextInput
            type="date"
            placeholder="Datum"
            value={formState.date.value}
            isInvalid={formState.date.isInvalid}
            onChange={(e) =>
              setFormState({
                ...formState,
                date: {
                  value: e.target.value,
                  isInvalid: handleValidation(e.target.value),
                },
              })
            }
          />
          <Select
            isSearchable={true}
            styles={customStyles}
            options={options}
            value={options.find((obj) => obj.value === formState.food.value)}
            //isInvalid={formState.rights.isInvalid}
            onChange={(e) => selectOnChange(e)}
            placeholder={'Vyberte pokrm'}
            noOptionsMessage={() => 'Žádný pokrm nenalezen'}
          />
          <div>{added && <Added>Nabídka byla přidána</Added>}</div>
          <div>{err && <LoginError>Neplatná data</LoginError>}</div>
          <Submit ref={ref} inactive={submitButtonInactive} onClick={handleSubmit}>
            Přidat nabídku
          </Submit>
          <FormInfoText linkText={'Zpět'} href={`/canteen/${id}`} />
        </Form>
      </Container>
    </Container100vh>
  )
}

export default AddOffer

const Container100vh = styled.div`
  min-height: calc(100vh - 208.88px);

  display: flex;
  justify-content: center;
  align-items: center;

  background-image: url('/img/background_texture.jpg');
  background-attachment: fixed;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;

  @media (min-width: 366px) {
    min-height: calc(100vh - 219.88px);
  }
`

const Container = styled.div`
  flex: 1;
  padding: 30px 0;
  height: 100%;
`

const Form = styled.div`
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 20px 20px 50px 20px;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  border-radius: 5px;
  background-color: white;

  @media (min-width: 500px) {
    padding: 20px 50px 50px 50px;
    width: 70%;
  }

  @media (min-width: 600px) {
    width: 50%;
  }

  @media (min-width: 900px) {
    width: 30%;
  }

  @media (min-width: 2000px) {
    width: 20%;
  }

  @media (min-width: 3000px) {
    width: 15%;
  }
`

interface SubmitProps {
  inactive: boolean
}

const Submit = styled.button<SubmitProps>`
  margin: 20px 0;
  border: none;
  padding: 15px;
  background-color: ${navy};
  color: white;
  border-radius: 5px;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  outline-color: #005471;
  cursor: pointer;
  ${(props) =>
    props.inactive &&
    css`
      pointer-events: none;
      cursor: default;
      color: ${backgroundGray};
      background-color: #00394d;
    `}
`

const Heading = styled.h2`
  text-align: center;
`

const LoginError = styled.span`
  color: #fc6969;
  font-weight: 500;
  font-size: 0.81em;
  position: absolute;
  margin-bottom: 0.75em;
  margin-top: -0.8em;
  display: flex;
  justify-content: flex-start;
`

const Added = styled(LoginError)`
  color: black;
`
