import { gql } from '@apollo/client'

export default gql`
  query Query($canteenId: Int!, $date: DateTime!) {
    generateMonthOffers(canteenId: $canteenId, date: $date) {
      date
      canteenId
      food {
        canteenId
        id
        name
        tags {
          name
        }
      }
    }
  }
`
