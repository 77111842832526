import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const Footer = () => {
  return (
    <Container>
      <Links>
        <NavLink to="/about">O nás</NavLink>
        <Spacer>|</Spacer>
        <NavLink to="/howItWorks">Jak to funguje</NavLink>
      </Links>
      <Disclaimer>
        Fotografie jídel jsou ilustrační. Jídlo může výjimečně vypadat jinak &#128512;
      </Disclaimer>
      <Copyright>
        &copy; &nbsp;
        <MailLink to="/about">Databáze jídel</MailLink>
        &nbsp;{new Date().getFullYear()}
      </Copyright>
    </Container>
  )
}

export default Footer

const Container = styled.div`
  padding: 10px 10%;
  padding-top: 30px;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
`

const Copyright = styled.p`
  text-align: center;
  font-weight: bold;
`

const MailLink = styled(Link)`
  color: black;
  text-decoration: none;
`

const Links = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;

  @media (max-width: 470px) {
    flex-direction: column;
  }
`

const NavLink = styled(Link)`
  color: black;

  @media (max-width: 470px) {
    margin: 0.25rem 0;
  }
`

const Href = styled.a`
  color: black;

  @media (max-width: 470px) {
    margin: 0.25rem 0;
  }
`

const Spacer = styled.span`
  margin: 0 15px;

  @media (max-width: 470px) {
    display: none;
  }
`

const Disclaimer = styled.p`
  text-align: center;
`
