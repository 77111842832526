import { gql } from '@apollo/client'

interface Canteens {
  id: number
  firstName: String
  lastName: String
}

export interface CanteenType {
  city: string
  items: Canteens
}

export default gql`
  query Query($id: Int!, $from: String!, $to: String!) {
    canteenOffers(from: $from, id: $id, to: $to) {
      id
      date
      food {
        id
        name
      }
    }
  }
`
