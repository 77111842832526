import React from 'react'
import styled from 'styled-components'
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined'
import CloseIcon from '@material-ui/icons/Close'
import { navy } from './colors'
import TextInput from './textInput'

interface Props {
  value: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  clearSearch: () => void
  placeholder?: string
}

const Search = ({ value, onChange, clearSearch, placeholder }: Props) => {
  return (
    <Container>
      <SearchIcon />
      <SearchBar placeholder={placeholder} type="text" value={value} onChange={onChange} />
      <Close onClick={clearSearch} />
    </Container>
  )
}

export default Search

const Container = styled.div`
  text-align: left;
  width: 200px;
  max-width: 200px;
  @media (min-width: 600px) {
    width: 500px;
    max-width: 500px;
  }
`

const SearchBar = styled(TextInput)`
  box-sizing: border-box;
  width: 200px;
  max-width: 200px;
  outline-color: ${navy};
  padding-left: 40px;
  padding-right: 40px;
  @media (min-width: 600px) {
    width: 500px;
    max-width: 500px;
  }
`

const SearchIcon = styled(SearchOutlinedIcon)`
  position: relative;
  top: 61px;
  left: 11px;
  color: ${navy};
`

const Close = styled(CloseIcon)`
  position: relative;
  bottom: 57px;
  left: 170px;
  color: ${navy};
  cursor: pointer;

  @media (min-width: 600px) {
    left: 465px;
  }
`
