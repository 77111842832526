import styled from 'styled-components'
import CloseIcon from '@material-ui/icons/Close'
import { useState } from 'react'

import { snowWhite } from '../../layout/colors'
import SelectAction from './Steps/SelectAction'

import GenerateAi from './Steps/GenerateAi'

interface Props {
  setModalOpened: React.Dispatch<React.SetStateAction<boolean>>
  tags: string[]
}

const Modal = (props: Props) => {
  const [step, setStep] = useState(0)

  const renderSteps = () => {
    switch (step) {
      case 0:
        return <SelectAction setStep={setStep} />
      case 1:
        return <GenerateAi tags={props.tags} />
    }
  }
  return (
    <Overlay>
      <Body>
        <Close onClick={() => props.setModalOpened(false)}>
          <CloseIcon />
        </Close>
        {renderSteps()}
      </Body>
    </Overlay>
  )
}

export default Modal

const SubmitButton = styled.button`
  background-color: #ee982b;
  color: white;
  display: flex;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  border: 2px solid #ee982b;
  border-radius: 5px;
  box-sizing: border-box;
  transition: 0.5s;
  padding: 10px 15px;
  height: fit-content;
  margin: 1rem auto;

  &:hover {
    box-shadow: 0rem 0.5rem 1.5rem rgb(0 0 0 / 30%);
  }
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`

const Body = styled.div`
  width: 50%;
  background-color: ${snowWhite};
  padding: 2rem 2rem;
  margin: 1rem auto;

  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  border-radius: 5px;

  h3,
  h2 {
    text-align: center;
    margin-bottom: 3rem;
  }
`

const Close = styled.div`
  margin-left: auto;
  width: fit-content;
  cursor: pointer;
`

const Loading = styled.div`
  text-align: center;
`
