import styled from 'styled-components'
import { useQuery } from '@apollo/client'
import { useHistory, useParams } from 'react-router-dom'

import OfferTable from './OfferTable'
import LoaderSpinner from '../../layout/loader'
import generateOffer from '../../queries/generateOffer'

const MenuMaker = () => {
  const { id, date: dateParam }: { id: string; date: string } = useParams()
  const history = useHistory()

  const { loading, data, error } = useQuery(generateOffer, {
    variables: { canteenId: parseInt(id), date: dateParam },
    context: { clientName: 'api2' },
  })

  if (error) {
    console.log(error)
  }

  if (loading) {
    return <LoaderSpinner color="#ee982b" />
  }

  return (
    <Container100vh>
      <Container>
        <Inner>
          <SubmitButton onClick={() => history.goBack()}>Vygenerovat znovu</SubmitButton>
          {!data || !data.generateMonthOffers.length ? (
            <EmptyMessage>Bohužel jsme nic neneašli :(</EmptyMessage>
          ) : (
            <OfferTable
              offer={data.generateMonthOffers}
              hideExpandButton
              disableFilter
              color="#ee982b"
            />
          )}
        </Inner>
      </Container>
    </Container100vh>
  )
}

export default MenuMaker

const EmptyMessage = styled.div``

const SubmitButton = styled.button`
  background-color: #ee982b;
  color: white;
  display: flex;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  border: 2px solid #ee982b;
  border-radius: 5px;
  box-sizing: border-box;
  transition: 0.5s;
  padding: 10px 15px;
  height: fit-content;
  margin: 1rem auto;

  &:hover {
    box-shadow: 0rem 0.5rem 1.5rem rgb(0 0 0 / 30%);
  }
`

const InputContainer = styled.div`
  display: flex;
  gap: 2rem;
  margin: 0 auto;
  width: fit-content;
  align-items: center;
`

const StyledSelect = styled.input``

const Container100vh = styled.div`
  min-height: calc(100vh - 208.88px);

  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;

  background-image: url('/img/background_texture.jpg');
  background-attachment: fixed;
  background-position: center;
  background-size: cover;

  @media (min-width: 366px) {
    min-height: calc(100vh - 219.88px);
  }
`

const Container = styled.div`
  flex: 1;
  height: 100%;
`

const Inner = styled.div`
  text-align: center;
  background-color: white;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  width: 80%;
  margin: 10px auto;
  border-radius: 5px;
  padding: 20px 20px;
`
