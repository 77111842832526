import styled from 'styled-components'
import React from 'react'
import Main from './Main'

const Home = () => {
  return <Wrapper />
}

export default Home

const Wrapper = styled(Main)`
  min-height: 100%;
`
