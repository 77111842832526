import React from 'react'
import styled from 'styled-components'
import PersonIcon from '@material-ui/icons/Person'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import DeleteIcon from '@material-ui/icons/Delete'
import { useMutation } from '@apollo/client'
import { Rating } from 'react-simple-star-rating'

import { navy } from '../../layout/colors'
import removeComment from '../../mutations/removeComment'
import comments from '../../queries/comments'
import useLogin from '../../hooks/useLogin'

interface Props {
  comment: any
  foodId: number
}

const getStars = (stars: number) =>
  [...Array(5)].map((val, i) => (
    <>
      {stars > i ? (
        <svg fill="currentColor" width="28" height="28" viewBox="0 0 24 24" className="star-svg">
          <path
            fill="rgb(255, 188, 11)"
            stroke="none"
            stroke-miterlimit="10"
            stroke-width="0"
            d="M12,17.27L18.18,21l-1.64-7.03L22,9.24l-7.19-0.61L12,2L9.19,8.63L2,9.24l5.46,4.73L5.82,21L12,17.27z"
          ></path>
        </svg>
      ) : (
        <svg fill="currentColor" width="28" height="28" viewBox="0 0 24 24" className="star-svg">
          <path
            fill="rgb(204, 204, 204)"
            stroke="none"
            stroke-miterlimit="10"
            stroke-width="0"
            d="M12,17.27L18.18,21l-1.64-7.03L22,9.24l-7.19-0.61L12,2L9.19,8.63L2,9.24l5.46,4.73L5.82,21L12,17.27z"
          ></path>
        </svg>
      )}
    </>
  ))

const Comment = ({ comment, foodId }: Props) => {
  const { user } = useLogin()
  const date = new Date(comment.comment.date)

  const username =
    comment.username.length > 11 && comment.username != 'Uživatel byl smazán'
      ? `${comment.username.substring(0, 9)}...`
      : comment.username

  const refetchQueries = {
    refetchQueries: [{ query: comments, variables: { id: foodId } }],
  }
  const [req] = useMutation(removeComment, {
    ...refetchQueries,
  })

  function handleDelete() {
    return req({ variables: { id: parseInt(comment.comment.id) } })
  }
  console.log(comment.comment.rating)

  return (
    <>
      <Container>
        <p>{comment.comment.text}</p>
        <div>{getStars(comment.comment.rating)}</div>
        <CommentData>
          <Text>
            <Person />
            {username}
          </Text>{' '}
          <Text>
            <TimeIcon />
            {date.getDate()}.{date.getMonth() + 1}.{date.getFullYear()}
            {user && user.adminLevel == '4' && (
              <Delete
                onClick={() => {
                  handleDelete()
                }}
              />
            )}
          </Text>
        </CommentData>
      </Container>
    </>
  )
}

export default Comment

const Container = styled.div`
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  border-radius: 5px;
  margin: 25px 0;
  padding: 15px;
`

const CommentData = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: gray;
  flex-direction: column;

  @media (min-width: 500px) {
    flex-direction: row;
  }
`

const Text = styled.p`
  display: inline-flex;
  align-items: center;
`

const Person = styled(PersonIcon)`
  margin-right: 5px;
`

const TimeIcon = styled(AccessTimeIcon)`
  margin-right: 5px;
`

const Delete = styled(DeleteIcon)`
  color: ${navy};
  cursor: pointer;
`
