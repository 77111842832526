import React from 'react'
import { useParams } from 'react-router'
import { useQuery } from '@apollo/client'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import styled from 'styled-components'

import canteen from '../../queries/canteen'
import ColorButton from '../../layout/ColorButon'
import LoaderSpinner from '../../layout/loader'
import OfferTable from './OfferTable'
import OfferSection from './OfferSection'
import useLogin from '../../hooks/useLogin'
import { Link } from 'react-router-dom'

const Canteen = () => {
  const { id }: { id: string } = useParams()
  const { user } = useLogin()

  const { loading, data } = useQuery(canteen, {
    variables: { id: parseInt(id) },
  })

  if (loading) {
    return <LoaderSpinner />
  }

  return (
    <>
      <Container100vh>
        <Container>
          <Inner>
            <h1>Jídelna {data.canteen.currentCanteen.name}</h1>
            <p>{data.canteen.currentCanteen.region} kraj</p>
            <StravaLink
              href="https://www.strava.cz/Strava/Stravnik/Jidelnicky?zarizeni=0304"
              target={'_blank'}
            >
              Strava.cz
            </StravaLink>
            <StravaLink style={{ marginLeft: '1rem' }} href="#search">
              Vyhledávání
            </StravaLink>
            <StravaLink style={{ marginLeft: '1rem' }} href={`/canteen/${id}/history`}>
              Historie
            </StravaLink>
            <OfferTable
              offer={data.canteen.offer}
              canteenUserId={data.canteen.currentCanteen.userId}
            />
            {user && (user.id === data.canteen.currentCanteen.userId || user.adminLevel == '4') && (
              <OfferColorButton
                child={
                  <AddPhoto to={`/addOffer/${data.canteen.currentCanteen.id}`}>
                    <AddCircleIcon />
                    Přidat nabídku
                  </AddPhoto>
                }
              />
            )}
            <h2 id="search">Nabídka pokrmů</h2>
            <OfferSection />
            {user && (user.id === data.canteen.currentCanteen.userId || user.adminLevel == '4') && (
              <div>
                <ColorButton
                  child={
                    <AddPhoto to={`/addFood/${data.canteen.currentCanteen.id}`}>
                      <AddCircleIcon />
                      Přidat jídlo
                    </AddPhoto>
                  }
                />
              </div>
            )}
          </Inner>
        </Container>
      </Container100vh>
    </>
  )
}

export default Canteen

const Container100vh = styled.div`
  min-height: calc(100vh - 208.88px);

  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;

  background-image: url('/img/background_texture.jpg');
  background-attachment: fixed;
  background-position: center;
  background-size: cover;

  @media (min-width: 366px) {
    min-height: calc(100vh - 219.88px);
  }
`

const Container = styled.div`
  flex: 1;
  height: 100%;
`

const Inner = styled.div`
  text-align: center;
  background-color: white;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  width: 80%;
  margin: 10px auto;
  border-radius: 5px;
  padding: 20px 20px;
`

const AddPhoto = styled(Link)`
  display: inline-flex;
  align-items: center;
  color: white;
  text-decoration: none;
  margin: 0 auto;
`

const OfferColorButton = styled(ColorButton)`
  margin-top: 20px;
`

const StravaLink = styled.a`
  background-color: #005471;
  color: white;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  border: 2px solid #005471;
  border-radius: 5px;
  box-sizing: border-box;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  text-decoration: none;
  display: inline-block;
  margin: 10px 0;
  padding: 10px 15px;

  &:hover {
    box-shadow: 0rem 0.5rem 1.5rem rgb(0 0 0 / 30%);
    //transform: scale(1.01);
  }
`
